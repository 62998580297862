import { Accept } from 'react-dropzone';
import { EventType } from 'shared/types/event';
import {
  EVENTS_ROUTES,
  QUESTIONNAIRES_EVENT_EDIT_ROUTES,
  QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES,
  QUESTIONNAIRES_EVENT_PREVIEW_ROUTES
} from 'modules/events/constants';
import {
  QUESTIONNAIRES_REQUEST_EDIT_ROUTES,
  QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES,
  QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES,
  REQUESTS_ROUTES
} from 'modules/requests/constant/paths';
import { BUILDER_ROUTES } from 'modules/builder/constants/builder';
import { QuestionnaireTaxType } from './types';

export const QUESTIONNAIRES_ROUTES = {
  QUESTIONNAIRES: '/questionnaires',
  QUESTIONNAIRES_PREVIEW_LINK: '/questionnaires/preview/',
  QUESTIONNAIRES_PREVIEW: 'preview/:questionnaireId/:pageId',
  QUESTIONNAIRES_PREVIEW_SUB: 'preview/:questionnaireId/:pageId/:subPageId',
  QUESTIONNAIRES_PREVIEW_BY_ID: (questionnaireId?: string, pageId?: string) =>
    `/questionnaires/preview/${questionnaireId ? questionnaireId : ''}${pageId ? `/${pageId}` : ''}`,
  QUESTIONNAIRES_PREVIEW_BY_PAGE: (questionnaireId: string, pageId?: string, subPageId?: string) =>
    `/questionnaires/preview/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`
};

export const PAGE_PREVIEW_PATH_BASE_ON_QUESTIONNAIRE_SOURCE = {
  template: QUESTIONNAIRES_ROUTES.QUESTIONNAIRES_PREVIEW_BY_PAGE,
  event: QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.PAGE_BY_ID,
  request: QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.PAGE_BY_ID
};

export const PAGE_BUILDER_PATH_BASE_ON_QUESTIONNAIRE_SOURCE = {
  template: BUILDER_ROUTES.BUILDER_PAGE_BY_ID,
  event: QUESTIONNAIRES_EVENT_EDIT_ROUTES.EDIT_PAGE_BY_ID,
  request: QUESTIONNAIRES_REQUEST_EDIT_ROUTES.EDIT_PAGE_BY_ID
};

export const LOGO_PATH_BASE_ON_QUESTIONNAIRE_SOURCE = {
  template: QUESTIONNAIRES_ROUTES.QUESTIONNAIRES,
  event: EVENTS_ROUTES.ROOT_LINK,
  request: REQUESTS_ROUTES.ROOT_LINK
};

export const PLAYGROUND_PATH_BASE_ON_QUESTIONNAIRE_SOURCE = {
  template: BUILDER_ROUTES.PLAYGROUND_PAGE_BY_ID,
  event: QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES.PLAYGROUND_PAGE_BY_ID,
  request: QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES.PLAYGROUND_PAGE_BY_ID
};

export const PREVIEW = 'preview';
export const UPLOAD_AREA_SUPPORTED_FILE_EXT: string[] = ['pdf', 'docx', 'pptx', 'txt', 'csv', 'xlsx', 'png', 'jpg', 'svg'];
export const ACCEPT_FILE: Accept = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'text/plain': [],
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'image/svg+xml': []
};
export const TIME_FOR_CHANGES_EXPIRED = 'Time for changes expired.';

export const COUNT_OF_LAST_BOOKED_SERVICES = 5;

export const eventTypeChips = {
  [EventType.Wedding]: {
    intlId: 'questionnaires.wedding',
    color: '#9254DE'
  },
  [EventType.Meeting]: {
    intlId: 'questionnaires.meeting',
    color: '#36CFC9'
  },
  [EventType.Corporate]: {
    intlId: 'questionnaires.corporate',
    color: '#FFA940'
  },
  [EventType.BirthdayParty]: {
    intlId: 'questionnaires.corporate',
    color: '#597EF7'
  }
};

export const VALUE_TYPE = {
  [QuestionnaireTaxType.Net]: { intlId: 'articles.netto' },
  [QuestionnaireTaxType.Gross]: { intlId: 'articles.brutto' }
};
