import { FormattedMessage } from 'react-intl';
import { Button, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { AddCircle16Regular, Edit16Regular } from '@fluentui/react-icons';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { IOfferDialogForm } from 'shared/types/offer';

interface IOfferModalUserActionsProps {
  openUserModal: (isNew: boolean) => void;
}

const OfferModalUserActions = ({ openUserModal }: IOfferModalUserActionsProps) => {
  const { values } = useFormikContext<IOfferDialogForm>();
  const { disableForEventManager } = useGetDataByRole();

  const handleNewCustomer = () => openUserModal(true);
  const handleUpdateCustomer = () => openUserModal(false);

  return (
    <>
      <Grid item xs={6} sm={6}>
        <Button
          fullWidth
          size="small"
          color="primary"
          variant="contained"
          startIcon={<AddCircle16Regular />}
          onClick={handleNewCustomer}
          disabled={disableForEventManager}
        >
          <FormattedMessage id="events.create-new-customer" />
        </Button>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Button
          fullWidth
          disabled={!Number.isInteger(values?.recipient?.id)}
          size="small"
          variant="outlined"
          startIcon={<Edit16Regular />}
          onClick={handleUpdateCustomer}
        >
          <FormattedMessage id="invoices.modal-update-customer-btn" />
        </Button>
      </Grid>
    </>
  );
};

export default OfferModalUserActions;
