import { useState, useEffect } from 'react';
import * as React from 'react';
import { FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { AsYouType, CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useIntl } from 'react-intl';

interface PhoneNumberInputProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  /** The phone number value as an unformatted string */
  value: string;
  /** Callback fired when the phone number changes */
  onChange: (value: string) => void;
  /** Default country for parsing and formatting (e.g., "DE") */
  defaultCountry?: CountryCode;
  name: string;
  id: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange, defaultCountry = 'DE', name, id, ...rest }) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    if (value) {
      const cleanedValue = value.replace(/[^\d+]/g, '');
      let parsedNumber = parsePhoneNumberFromString(cleanedValue);

      if (!parsedNumber) {
        parsedNumber = parsePhoneNumberFromString(cleanedValue, defaultCountry);
      }

      if (parsedNumber) {
        setInputValue(parsedNumber.formatInternational());
      } else {
        setInputValue(cleanedValue);
      }
    } else {
      setInputValue('');
    }
  }, [value, defaultCountry]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const unformattedNumber = input.replace(/[^\d+]/g, '');

    let country = defaultCountry;
    const parsedNumber = parsePhoneNumberFromString(unformattedNumber);

    if (parsedNumber && parsedNumber.country) {
      country = parsedNumber.country;
    }

    const formatter = new AsYouType(country);
    const formattedInput = formatter.input(unformattedNumber);

    setInputValue(formattedInput);
    onChange?.(unformattedNumber);
  };

  const isError = (): boolean => {
    if (!inputValue) {
      return false;
    }

    let parsedNumber = parsePhoneNumberFromString(inputValue);

    if (!parsedNumber) {
      parsedNumber = parsePhoneNumberFromString(inputValue, defaultCountry);
    }

    return !parsedNumber || !parsedNumber.isValid();
  };

  return (
    <>
      <TextField {...rest} name={name} id={id} value={inputValue} onChange={handleInputChange} error={isError()} />
      <FormHelperText error id="phone-number-helper">
        {isError() ? intl.formatMessage({ id: 'users.phone-number-invalid' }) : rest.helperText}
      </FormHelperText>
    </>
  );
};

export default PhoneNumberInput;
