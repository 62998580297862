import { format } from 'date-fns';
import { checkStringIsDate } from 'shared/utils/date';
import { formatPriceByLanguage } from 'shared/utils/price';

interface IGenerateCellDataTableDashboard {
  value: string;
  dateFormat: string;
  currency?: string;
  isCurrency?: boolean;
  languageCode: string;
}

interface IGetValueCurrency {
  value: string | number;
  currency?: string;
  isCurrency?: boolean;
  languageCode: string;
}

/**
 * Method for outputting value and currency to result cell of TableDashboardWidget
 * @param value - value of parameter
 * @param dateFormat - dateFormat from Settings
 * @param currency - currency from Settings
 * @param isCurrency - flag for hide or show currency (data from backend)
 * @param languageCode - default language code from General Settings
 */
export const generateValueCurrency = ({ value, currency, isCurrency, languageCode }: IGetValueCurrency) => {
  const valResult = isCurrency ? `${formatPriceByLanguage(value, languageCode)}${currency ?? ''}` : `${value}`;
  return !!value ? valResult : '';
};

/**
 * Method for outputting data in the appropriate format to cell of TableDashboardWidget
 * @param value - value of parameter
 * @param dateFormat - dateFormat from Settings
 * @param currency - currency from Settings
 * @param isCurrency - flag for hide or show currency (data from backend)
 * @param languageCode - default language code from General Settings
 */
export const generateCellDataTableDashboard = ({
  value,
  dateFormat,
  currency,
  isCurrency,
  languageCode
}: IGenerateCellDataTableDashboard): string => {
  if (checkStringIsDate(value)) {
    return format(new Date(value), dateFormat);
  }
  return generateValueCurrency({ value, isCurrency, currency, languageCode });
};
