import { styled } from '@mui/material/styles';
import { Box, InputAdornment as MuiInputAdornment, TextField as MuiTextField } from '@mui/material';

export const TextField = styled(MuiTextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    alignItems: 'normal',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    paddingRight: '0'
  }
}));

export const InputAdornment = styled(MuiInputAdornment)(() => ({
  padding: '9px 14px',
  minHeight: '100%',
  maxHeight: '100%',
  height: 'unset',
  marginLeft: 0,
  justifyContent: 'center'
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary
}));
