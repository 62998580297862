import { Theme, alpha } from '@mui/material/styles';
import { ColorProps } from 'shared/types/extended';
import getColors from 'shared/utils/getColors';

interface Props {
  variant: ColorProps;
  theme: Theme;
}

function getColor({ variant, theme }: Props) {
  const colors = getColors(theme, variant);
  const { light } = colors;

  return {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: light
    },
    '&.MuiOutlinedInput-root': {
      borderRadius: '6px',
      backgroundColor: theme.palette.mode === 'dark' ? alpha('#000', 0.05) : alpha('#fff', 0.21),
      padding: '8px 12px'
    },
    '& .MuiInputBase-input': {
      padding: '0 !important'
    },
    '& .MuiInputBase-inputAdornedStart ': {
      padding: '0 0 0 10px !important'
    },
    '&.Mui-focused': {
      borderColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.5)} 0px 0px 0px 2px inset`,
      borderRadius: '6px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `transparent`
      }
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1)
      }
    }
  };
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        borderRadius: '6px',
        background: '#ffffff',
        input: {
          padding: '8px 12px'
        },
        multiline: {
          padding: '8px 12px'
        },
        notchedOutline: {
          borderColor: theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1),
          borderRadius: '6px'
        },
        root: {
          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme })
          }
        },
        inputSizeSmall: {
          padding: '7.5px 8px 7.5px 12px'
        },
        inputMultiline: {
          padding: 0
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme })
      }
    }
  };
}
