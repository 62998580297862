import { IQuestionnaire } from 'modules/questionnaires/types';
import { ILanguage } from './language';
import { IVenue } from './venue';
import { IDetails } from './user';
import { ERequestStatus, IOfferInsideRequest, IRequestDB, IRequestListItem, RequestStatusPending } from './requests';
import { IUsersListItem } from './users';

export interface ILoginViewSetting {
  loginLogoUrl: string;
  loginBackgroundUrl: string;
  loginGreetingText: string;
  loginTextSize: number;
  loginFontFamily: string;
  loginAccentColor: string;
  loginTextColor: string;
  isLoginLogoBlurred: boolean;
}

interface IAdmin {
  id: number;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: string;
  profilePictureUrl: string;
  isActive: boolean;
  additionalContacts: any[];
}

interface ITenant {
  id: number;
  name: string;
  customUrl: string;
  isActive: boolean;
  loginViewSetting?: ILoginViewSetting;
  admin?: IAdmin;
}

export interface IContactPeople {
  id: number;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: string;
  isActive: boolean;
  additionalContacts: any[];
  details?: IDetails;
  tenant?: ITenant;
}

export interface IEventManager {
  id: number;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: string;
  profilePictureUrl: string;
  isActive: boolean;
  fullName: string;
  additionalContacts: any[];
  tenant: ITenant;
}

export type IEventType = 'Wedding' | 'Meeting' | 'Corporate';

export enum EventType {
  Wedding = 'Wedding',
  Meeting = 'Meeting',
  Corporate = 'Corporate',
  BirthdayParty = 'Birthdate Party'
}

export enum EventCreationStatusType {
  Ready = 'Ready', // Event was created --> Default status
  InPlanning = 'InPlanning', // Event was created --> Default status
  Pending = 'Pending', //  Event questionnaire is being created
  SyncFailed = 'SyncFailed',
  Closed = 'Closed', //Trigger: Manual
  EventDay = 'EventDay',
  PostEvent = 'PostEvent', //After event day.
  Invoiced = 'Invoiced', // after PostEvent and  An invoice is sent via mail/email when Post Event status is reached.
  Canceled = 'Canceled', //Trigger: Manual
  OnHold = 'OnHold' //Trigger: Manual
}

export interface IEvent<D = Date> {
  id: number;
  name: string;
  eventType: IEventType;
  eventDate: D;
  deadLine: D;
  language: ILanguage;
  venue: IVenue;
  contactPeople: IContactPeople[];
  eventManager: IEventManager;
  questionnaire: IQuestionnaire;
  isActive: boolean;
  eventState: EventCreationStatusType;
  offer?: IOfferInsideRequest;
  actions: () => JSX.Element;
}

export type EventReducedType = Pick<IEvent<string>, 'id' | 'name' | 'eventType' | 'deadLine' | 'eventDate'> & {
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
};

export interface IEventInfo {
  id: number;
  name: string;
  contactPeople?: IContactPeople[];
  eventManager: IEventManager;
}

export enum EEventQueryKeys {
  AllEvents = 'events'
}

export interface IProfileData {
  id: number;
  name: string;
  anonymousLinkPrefix: string;
  profileType: 'Event' | 'Request';
  eventType: IEventType;
  eventDate: string;
  deadLine?: string;
  venue: IVenue | null;
  contactPeople: IContactPeople[] | null;
  questionnaire: IQuestionnaire | null;
  isActive: boolean;
  eventState: EventCreationStatusType | ERequestStatus | typeof RequestStatusPending;
  profileManager: IEventManager | null;
  offer?: IOfferInsideRequest | null;
  originalEntity: IEvent<string> | IEvent<Date> | IRequestListItem | IUsersListItem | IRequestDB | null;
}
