import { CSSProperties, ReactNode, forwardRef } from 'react';
import { Card, CardContent, CardContentProps, CardHeader, CardHeaderProps, CardProps, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Highlighter from './Highlighter';

const headerSX = {
  p: '10px 20px',
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

export interface MainCardProps extends Omit<CardProps, 'title' | 'content'> {
  border?: boolean;
  boxShadow?: boolean;
  children: ReactNode;
  subheader?: ReactNode;
  style?: CSSProperties;
  content?: boolean;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  divider?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: ReactNode;
  codeHighlight?: boolean;
  codeString?: string;
  modal?: boolean;
}

const MainCard = forwardRef<HTMLDivElement, MainCardProps>(
  (
    {
      border = true,
      boxShadow = false,
      children,
      subheader,
      content = true,
      contentSX = {},
      darkTitle = false,
      divider = true,
      elevation = 0,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight = false,
      codeString,
      modal = false,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    const finalBoxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

    return (
      <Card
        elevation={elevation}
        ref={ref}
        {...others}
        sx={{
          position: 'relative',
          overflow: 'visible',
          border: border ? '1px solid' : 'none',
          borderRadius: '10px',
          borderColor: theme.palette.mode === 'dark' ? '#00000020' : theme.palette.grey.A800,
          boxShadow: finalBoxShadow ? shadow || 'rgba(0, 0, 0, 0.02) 0px 0px 50px 50px, rgba(27, 31, 35, 0.05) 0px 0px 0px 1px' : 'inherit',

          ...(codeHighlight && {
            '& pre': {
              m: 0,
              p: '12px !important',
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.75rem'
            }
          }),
          ...(modal && {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: `calc(100% - 50px)`, sm: 'auto' },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`
            }
          }),
          ...sx
        }}
      >
        {!darkTitle && title && (
          <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'h5' }} title={title} action={secondary} subheader={subheader} />
        )}
        {darkTitle && title && <CardHeader sx={headerSX} title={<Typography variant="h4">{title}</Typography>} action={secondary} />}
        {title && divider && <Divider />}
        {content ? <CardContent sx={contentSX}>{children}</CardContent> : children}
        {codeString && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Highlighter codeString={codeString} codeHighlight={codeHighlight} />
          </>
        )}
      </Card>
    );
  }
);

export default MainCard;
