import { FC } from 'react';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Box } from '@mui/material';
import { DateTimePicker, LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ITableWidgetColumnRules, ITableWidgetRowRules, TABLE_COLUMN_TYPES } from 'modules/builder/types/tableWidgetType';
import { TimeFormatEnum } from 'shared/configs/config';
import { useCurrentDateFormat, useCurrentTimeFormat } from 'shared/hooks/regionalSettings';
import { TextFieldPropsType } from 'modules/builder/components/widgets/TableWidget/types';
import * as Styles from './OrganisationPlanTableEditableCell.styles';

interface IOrganisationPlanTableEditableCellProps {
  value: any;
  row: number;
  column: number;
  settings?: ITableWidgetColumnRules;
  settingsRow?: ITableWidgetRowRules;
  isRowSettingsAvailable?: boolean;
}

const OrganisationPlanTableEditableCell: FC<IOrganisationPlanTableEditableCellProps> = ({ value: initialValue, settings }) => {
  const readOnly = true;

  const dateFormat = useCurrentDateFormat();
  const timeFormat = useCurrentTimeFormat();
  const isTwelveHoursFormat = timeFormat === TimeFormatEnum.Twelve;

  const dateValue = initialValue ? new Date(initialValue) : null;

  if (settings?.type === TABLE_COLUMN_TYPES.time) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          readOnly
          disabled
          ampm={isTwelveHoursFormat}
          openTo="hours"
          views={['hours', 'minutes']}
          format={isTwelveHoursFormat ? 'hh:mm a' : 'HH:mm'}
          value={dateValue}
          onChange={() => null}
        />
      </LocalizationProvider>
    );
  }

  if (settings?.type === TABLE_COLUMN_TYPES.date) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker readOnly disabled value={dateValue} format={dateFormat?.format} onChange={() => null} />
      </LocalizationProvider>
    );
  }

  if (settings?.type === TABLE_COLUMN_TYPES.dateTime) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          readOnly
          disabled
          ampm={isTwelveHoursFormat}
          value={dateValue}
          onChange={() => null}
          format={isTwelveHoursFormat ? `${dateFormat?.format} hh:mm a` : `${dateFormat?.format} HH:mm`}
        />
      </LocalizationProvider>
    );
  }

  if (settings?.type === TABLE_COLUMN_TYPES.number) {
    return (
      <NumberFormat
        disabled
        readOnly
        inputProps={{ readOnly }}
        customInput={Styles.TextField as React.ComponentType<TextFieldPropsType>}
        isNumericString
        thousandSeparator
        placeholder=""
        value={initialValue}
      />
    );
  }

  return (
    <Box>
      <Styles.TextField disabled inputProps={{ readOnly }} value={initialValue} fullWidth placeholder="" multiline />
    </Box>
  );
};

export default OrganisationPlanTableEditableCell;
