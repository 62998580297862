import { Theme, alpha } from '@mui/material';

// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          '& .MuiTooltip-tooltip': {
            borderRadius: '4px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
            backgroundColor: theme.palette.mode === 'dark' ? alpha('#000', 0.81) : alpha('#fff', 0.81),
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            color: theme.palette.mode === 'dark' ? 'white' : 'black'
          }
        },
        arrow: {
          '&::before': {
            borderRadius: '1px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
            backgroundColor: theme.palette.mode === 'dark' ? alpha('#000', 0.81) : alpha('#fff', 0.81),
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)'
          }
        }
      }
    }
  };
}
