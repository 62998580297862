import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  padding: '22px 0 10px 0',
  '& .MuiGrid-container': {
    justifyContent: 'flex-end'
  }
}));

export const Section = styled(Stack)(() => ({
  textAlign: 'left',
  paddingBottom: 10,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  '& > .MuiBox-root': {
    width: 100
  }
}));

export const Title = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.primary.main,
  paddingBottom: 0
}));

export const Value = styled(Box)(() => ({
  textAlign: 'right'
}));

export const additionalStylesIconInput = {
  marginTop: '-6px',
  '& .MuiInputAdornment-root, & .MuiOutlinedInput-input ': {
    padding: '5px 4px 5px 4px',
    textAlign: 'right'
  }
};

export const finalAmount = {
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 3,
    height: '1px',
    width: '96%',
    margin: '0 auto',
    background: '#000000',
    left: 0
  }
};
