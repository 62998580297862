import { DefaultConfigProps } from 'shared/types/config';
import { ICurrency, IObjectData } from 'shared/types/tenants';

export const drawerWidth = 260;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: '/dashboard',
  fontFamily: `'Poppins', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  modeForPlayground: 'light'
};

export const rolesUser: IObjectData[] = [
  { id: 1, value: 'tenant_admin', label: 'Tenant-Admin', formatId: 'tenants.tenant-Admin' },
  { id: 2, value: 'admin', label: 'Global-Admin', formatId: 'tenants.global-admmin' },
  { id: 3, value: 'venue_manager', label: 'Venue-Manager', formatId: 'tenants.venue-manager' },
  { id: 4, value: 'event_manager', label: 'Event-Manager', formatId: 'tenants.event-manager' },
  { id: 4, value: 'customer', label: 'Customer', formatId: 'tenants.customer' }
  // { id: 3, label: 'tenant_admin', value: 'Customer' }
];

export const rolesUserForGlobalAdmin: IObjectData[] = [
  { id: 1, value: 'tenant_admin', label: 'Tenant-Admin', formatId: 'tenants.tenant-Admin' },
  { id: 2, value: 'admin', label: 'Global-Admin', formatId: 'tenants.global-admmin' }
  // { id: 3, label: 'tenant_admin', value: 'Customer' }
];

export const rolesUserInsideTenant: IObjectData[] = [
  { id: 1, value: 'tenant_admin', label: 'Tenant-Admin', formatId: 'tenants.tenant-Admin' },
  { id: 3, value: 'venue_manager', label: 'Venue-Manager', formatId: 'tenants.venue-manager' },
  { id: 4, value: 'event_manager', label: 'Event-Manager', formatId: 'tenants.event-manager' },
  { id: 4, value: 'customer', label: 'Customer', formatId: 'tenants.customer' }
  // { id: 3, label: 'tenant_admin', value: 'Customer' }
];

export const rolesUserForVenueManager: IObjectData[] = [
  { id: 4, value: 'event_manager', label: 'Event-Manager', formatId: 'tenants.event-manager' },
  { id: 4, value: 'customer', label: 'Customer', formatId: 'tenants.customer' }
];

export const currencyData: ICurrency[] = [
  { code: 'USD', id: 1, name: 'United States dollar', symbol: '$' },
  { code: 'EURO', id: 2, name: 'Euro', symbol: '€' }
];

export const languageTenant: IObjectData[] = [
  { id: 1, value: 'English (United States)', code: 'en', locale: 'en-US' },
  { id: 2, value: 'German (Germany)', code: 'de', locale: 'de-DE' }
];

export enum TimeFormatEnum {
  Twelve = 'Twelve',
  TwentyFour = 'TwentyFour'
}

export const timeformatData: IObjectData[] = [
  { label: '24 hours', value: TimeFormatEnum.TwentyFour, formatId: 'tenants.24-hours' },
  { label: '12 hours', value: TimeFormatEnum.Twelve, formatId: 'tenants.12-hours' }
];

export default config;
