import { TO_FIXED_FOR_PRICE } from 'modules/pricing-sidebar/constants/inputs';

/*Method to return new Brutto, if netto or tax was changed
 * @param netto - netto value
 * @param tax - tax
 * @return - brutto
 *  */
export const bruttoArticle = (netto: number | string, tax: number | string): number => {
  const result = Number(+netto + (+netto * +tax) / 100);
  return Number(result.toFixed(TO_FIXED_FOR_PRICE));
};

/*Method to return new Netto, if brutto or tax was changed
 * @param brutto - brutto value
 * @param tax - tax
 * @return - netto
 * */
export const nettoArticle = (brutto: number | string, tax: number | string): number => {
  const result = Number(+brutto / (1 + +tax / 100));
  return Number(result.toFixed(TO_FIXED_FOR_PRICE));
};
