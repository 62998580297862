import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Box, Button, Tab } from '@mui/material';
import {
  addOrgPlanLink,
  removeOrgPlanLink,
  updateActiveLinkingSettings,
  updateOrgPlanLink
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { ActiveLinkingSettingsType } from 'store/organisation-plan-template/organization-plan-template.types';
import OrganisationPlanTabPanel from 'modules/organisation-plan-templates/components/OrganisationPlanTabPanel';
import * as Styles from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateQuestionnaireInfoSettings/OrganisationPlanTemplateQuestionnaireInfoSettings.styles';
import { LinkingAction } from 'shared/types/questioner-template-link';
import { useDispatch, useSelector } from 'store';
import { ILinkingPageNav } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { selectAllOrgPlanLinks } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { transformPageForSelect } from 'modules/builder/components/SettingsBar/LinkingTab/utils';
import { filterOrgPlanLinksByTarget } from 'modules/organisation-plan-templates/utils/filterLinks';
import DynamicFields from 'modules/builder/components/SettingsBar/LinkingTab/DynamicFields';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { IOrgPlanLinkObjectForSelect } from './types';

const INIT_ORG_PLAN_LINK: Omit<IOrgPlanLinkObjectForSelect, 'linkId'> = {
  action: LinkingAction.hide,
  source: { pageId: undefined, subPageId: null, widgetId: undefined },
  target: {}
};

interface IOrganisationPlanTemplateLinkingTabProps {
  target: ActiveLinkingSettingsType;
}

const OrganisationPlanTemplateLinkingTab = ({ target }: IOrganisationPlanTemplateLinkingTabProps) => {
  const [answerOptions, setAnswerOptions] = useState<ILinkingPageNav[]>([]);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { questionnaireTemplateId } = useParams();

  const { data: builderPages } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });

  const allLinks = useSelector(selectAllOrgPlanLinks);
  const links = filterOrgPlanLinksByTarget(target, allLinks);

  useEffect(() => {
    if (!builderPages) return;

    const options = transformPageForSelect(builderPages, null, undefined, []);
    setAnswerOptions(options);
  }, [builderPages]);

  const closeSettings = () => {
    dispatch(updateActiveLinkingSettings(null));
  };

  const addNewLinkHandler = useCallback(() => {
    dispatch(addOrgPlanLink({ ...INIT_ORG_PLAN_LINK, target }));
  }, [dispatch, target]);

  useLayoutEffect(() => {
    if (!links.length) {
      addNewLinkHandler();
    }
  }, [links.length, addNewLinkHandler]);

  const removeLinkHanlder = (linkId: IOrgPlanLinkObjectForSelect['linkId']) => {
    dispatch(removeOrgPlanLink(linkId));
  };

  const updateLinkHandler = ({
    link,
    linkId
  }: {
    link: Partial<IOrgPlanLinkObjectForSelect>;
    linkId: IOrgPlanLinkObjectForSelect['linkId'];
  }) => {
    dispatch(updateOrgPlanLink({ linkId, link }));
  };
  return (
    <Styles.BoxWrapper sx={{ p: '10px' }}>
      <Styles.TabsBoxWrapper>
        <Styles.TabsContainer value={0} aria-label="linking-settings">
          <Tab label={intl.formatMessage({ id: 'builder.linking' })} />
        </Styles.TabsContainer>
      </Styles.TabsBoxWrapper>
      <OrganisationPlanTabPanel
        value={0}
        activeValue={0}
        role="linking-tab-panel"
        id={`linking-tab-panel`}
        aria-labelledby={`linking-tab-section`}
      >
        <Box sx={{ padding: '20px' }}>
          <DynamicFields
            links={links}
            options={answerOptions}
            addNewLinkHandler={addNewLinkHandler}
            removeLinkHanlder={removeLinkHanlder}
            updateLinkHandler={updateLinkHandler}
          />
        </Box>
      </OrganisationPlanTabPanel>
      <Styles.ButtonsContainer>
        <Button variant="outlined" fullWidth onClick={closeSettings}>
          <FormattedMessage id="builder.close" />
        </Button>
      </Styles.ButtonsContainer>
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanTemplateLinkingTab;
