import { useCallback, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { DATA_SYNTAX, DateSyntaxFormat } from 'modules/settings/constants';
import { useSelector } from 'store';
import { selectQuestionnaireSettings } from 'store/builder/builder.selectors';
import { INDIVIDUAL_TAX, LEGAL_TAX } from 'shared/сonstants';
import { useGetTenantById } from 'api/hooks/tenant/useGetTenant';
import useGeneralSettings from './useGeneralSettings';

/** This is hook for getting current date format selected in the general settings. */
export const useCurrentDateFormat = (): DateSyntaxFormat => {
  const { tenant } = useGeneralSettings();
  const { dateSyntax } = useSelector(selectQuestionnaireSettings);
  const dateSyntaxFormat = tenant?.regionalSettings?.dateSyntax ?? dateSyntax;
  return DATA_SYNTAX.find((i) => i.value === dateSyntaxFormat) ?? DATA_SYNTAX[0];
};

/**
 * This is hook for getting current date from string formatted according regional settings.
 * @param {string} date - date in string format [not required]
 * */
export const useCurrentDateFormatter = (date?: string) => {
  const currentDateFormat = useCurrentDateFormat();
  const dateFormat = currentDateFormat?.format || 'dd.MM.yyyy';

  return format(parseISO(date || new Date().toISOString()), dateFormat);
};

type useFormatCurrentDateReturnType = (date?: string) => string;
/**
 * This is hook just copy of useCurrentDateFormatter, need it because useCurrentDateFormatter you cannot use inside functions, conditions etc.
 * @param {string} date - date in string format [not required]
 * */
export const useFormatCurrentDate = (): useFormatCurrentDateReturnType => {
  const currentDateFormat = useCurrentDateFormat();
  const formatFn = useCallback(
    (date?: string) => {
      const dateFormat = currentDateFormat?.format || 'dd.MM.yyyy';
      return format(parseISO(date || new Date().toISOString()), dateFormat);
    },
    [currentDateFormat?.format]
  );
  return formatFn;
};

/** This is hook for getting current time format selected in the general settings. */
export const useCurrentTimeFormat = () => {
  const { tenant } = useGeneralSettings();
  const { timeFormat } = useSelector(selectQuestionnaireSettings);
  return tenant?.regionalSettings?.timeFormat ?? timeFormat;
};

/** This is hook for getting current currency symbol from currency code selected in the general settings. */
export const useCurrentCurrencyFormat = () => {
  const { regionalSettings } = useGeneralSettings();
  return regionalSettings?.currency?.symbol;
};

/** This is hook for getting settings currency symbol from currency code and taxes of the tenant general settings. */
export const useSettingsPanelPrice = (tenantId?: number) => {
  const { regionalSettings } = useGeneralSettings();
  const { user } = useGeneralSettings();
  const isAdmin = user?.role === 'admin';
  const { data: tenant } = useGetTenantById({
    id: tenantId?.toString()
  });
  const currencySign = regionalSettings?.currency?.symbol;
  return useMemo(
    () => ({
      legalTax: regionalSettings?.legalTax ?? LEGAL_TAX,
      individualTax: regionalSettings?.individualTax ?? INDIVIDUAL_TAX,
      currencyCode: isAdmin && tenantId ? tenant?.regionalSettings.currency.symbol : currencySign
    }),
    [regionalSettings, tenant, currencySign, isAdmin, tenantId]
  );
};
