import { AddCircle20Regular, Delete20Regular } from '@fluentui/react-icons';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { LINKING_ACTION_OPTIONS } from 'modules/builder/constants/builderLinkingSettings';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ILinkSource } from 'shared/types/questioner-template-link';
import CollapsibleSection from './CollapsibleSection';
import {
  ILinkObjectForSelect,
  ILinkingPageNav,
  ILinkingWidgetNav,
  LinkingAction,
  RemoveLinkHandlerType,
  UpdateLinkHandlerType,
  UpdateSelectedFnType
} from './types';

const key = 'linkList';

interface IDynamicFieldsProps<T = Omit<ILinkObjectForSelect, 'target'>> {
  options: ILinkingPageNav[];
  links: T[];
  addNewLinkHandler: () => void;
  removeLinkHanlder: RemoveLinkHandlerType;
  updateLinkHandler: UpdateLinkHandlerType<T>;
}
const DynamicFields = ({ options, links, addNewLinkHandler, removeLinkHanlder, updateLinkHandler }: IDynamicFieldsProps): ReactElement => {
  const [isSelectOpen, setIsSelectOpen] = useState<string | null>(null);

  const selectSourceOption: UpdateSelectedFnType = (option, linkId) => {
    updateLinkHandler({ linkId, link: { source: option } });
  };

  const selectActionOption = (linkId: ILinkObjectForSelect['linkId'], action: LinkingAction) => {
    updateLinkHandler({ linkId, link: { action } });
  };

  const getSelectedOptionLabel = (selectedOption: Partial<ILinkSource>) => {
    const page = options.find((pageItem) => pageItem.id === selectedOption.pageSystemId);
    let widget: ILinkingWidgetNav | undefined;
    if (selectedOption.subPageSystemId) {
      const subPage = page?.subPages?.find((subPageItem) => subPageItem.id === selectedOption.subPageSystemId);
      widget = subPage?.widgets?.find((widgetItem) => widgetItem.id === selectedOption.widgetId);
    } else {
      widget = page?.widgets?.find((widgetItem) => widgetItem.id === selectedOption.widgetId);
    }
    const option = widget?.options?.find((optionItem) => optionItem.id === selectedOption.optionId);

    if (widget?.label && option?.label) {
      return `${widget?.label ?? ''} > ${option?.label ?? ''}`;
    } else {
      return 'Select Option';
    }
  };

  return (
    <>
      {links?.map((linkObj, index, array) => (
        <Grid container rowSpacing="20px" marginTop={index !== 0 ? '30px' : undefined} alignItems={'center'} key={linkObj.linkId}>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InputLabel id={`${key}[${index}].action`}>
                  <FormattedMessage id="builder.action" />
                </InputLabel>
                <IconButton
                  disabled={array.length === 1 && !linkObj.source.optionId}
                  onClick={() => removeLinkHanlder(linkObj.linkId)}
                  color="error"
                >
                  <Delete20Regular />
                </IconButton>
              </Box>
              <Select
                labelId={`${key}[${index}].action`}
                id={`${key}[${index}].action`}
                name={`${key}[${index}].action`}
                onChange={(e) => {
                  selectActionOption(linkObj.linkId, e.target.value as LinkingAction);
                }}
                value={linkObj.action}
              >
                {LINKING_ACTION_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.formatId ? <FormattedMessage id={option.formatId} /> : option.label}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel id={`${key}[${index}].source`}>
                <FormattedMessage id="builder.info-from-answer" />
              </InputLabel>
              <Select
                labelId={`${key}[${index}].source`}
                id={`${key}[${index}].source`}
                name={`${key}[${index}].source`}
                fullWidth
                value={linkObj.source}
                renderValue={() => <div style={{ whiteSpace: 'pre-wrap' }}>{getSelectedOptionLabel(linkObj.source)}</div>}
                open={isSelectOpen === linkObj.linkId}
                onOpen={() => setIsSelectOpen(linkObj.linkId)}
                onClose={() => setIsSelectOpen(null)}
              >
                {/* TODO: add loading state when options is not ready */}

                {options.map((option) => (
                  <CollapsibleSection
                    key={option.id}
                    page={option}
                    updateSelectedId={selectSourceOption}
                    selectedOption={linkObj?.source}
                    linkId={linkObj.linkId}
                    setIsSelectOpen={setIsSelectOpen}
                  />
                ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            component="label"
            size="small"
            startIcon={<AddCircle20Regular />}
            onClick={addNewLinkHandler}
          >
            <FormattedMessage id="builder.add-additional-field" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DynamicFields;
