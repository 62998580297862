import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { formatPriceByLanguage } from 'shared/utils/price';
import { IAppearanceSettings, QuestionnaireTaxType } from 'modules/questionnaires/types';

export interface IBaseSettings {
  code: string;
  symbol: string;
  taxType: QuestionnaireTaxType;
  appearanceSettings?: IAppearanceSettings;
  timeFormat?: string;
  individualTax?: number;
  legalTax?: number;
  dateSyntax?: string;
}
interface IFormatPrice {
  value: string | number;
  styles?: SxProps<Theme>;
  appearanceSettings?: IAppearanceSettings;
  baseSettings: IBaseSettings | undefined;
  isHideSymbol?: boolean;
}

/**
 * Output price in german number format or format selected in  tenant's regional settings
 * @param value: price
 * @param styles: additional styles
 * @param appearanceSettings: custom appearance settings
 */
const FormatPrice = ({ value, baseSettings, isHideSymbol }: IFormatPrice) => {
  if (typeof value === 'string') return <></>;
  return (
    <>
      {formatPriceByLanguage(value, baseSettings?.code)}
      {!isHideSymbol && baseSettings?.symbol}
    </>
  );
};

export default FormatPrice;
