import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, CircularProgress, InputLabel, Stack, TextField, Tooltip, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { EInvoiceEditorArticleType, IInvoiceEditor } from 'shared/types/invoice';
import { useGetInvoicePrepaymentItems } from 'api/hooks/invoices/useGetInvoicePrepaymentItems';
import { useGetInvoiceArticleItems } from 'api/hooks/invoices/useGetInvoiceArticleItems';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import {
  addBasePriceUnitWordingToArticle,
  addInnerIdToArticle,
  checkIsImportedFromEvent,
  generateListArticlesFromEventArticles,
  updateOrderArticle
} from 'modules/invoices/utils/prepareData';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import { IEditorArticle } from 'modules/editor/types/editor';
import * as Styles from './GreetingTextImportButtons.styles';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EInvoiceEditorArticleType | null;
}

const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const GreetingTextImportButtons = () => {
  const intl = useIntl();
  const theme = useTheme();
  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);
  const { getFieldProps, values, setFieldValue } = useFormikContext<IInvoiceEditor>();
  const { mutateAsync: getPrepayments, isLoading: isPrepaymentLoading } = useGetInvoicePrepaymentItems({});
  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetInvoiceArticleItems();

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  const handleImportPrepayments = async () => {
    const type = EInvoiceEditorArticleType.ImportedPrepayment;
    const res = await getPrepayments(values?.data?.id); // EventId
    const articlesWithInnerId = addInnerIdToArticle(res);

    if (checkIsImportedFromEvent(values?.articles, type)) {
      return setImportedAgain({
        isOpen: true,
        items: articlesWithInnerId,
        type: EInvoiceEditorArticleType.ImportedPrepayment
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, articlesWithInnerId));
  };

  const handleImportArticles = async () => {
    const type = EInvoiceEditorArticleType.Imported;
    const res = await getArticles(values?.data?.id); // TODO: O: delete if ok : values.eventId
    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);

    if (checkIsImportedFromEvent(values?.articles, type)) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EInvoiceEditorArticleType.Imported
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListArticlesFromEventArticles(importedAgain?.type, values?.articles, importedAgain?.items);
    setFieldValue('articles', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articles', updateOrderArticle(values?.articles, importedAgain?.items));
    handleCloseWarningImport();
  };

  return (
    <>
      <BaseStyles.WrapperContent>
        <Styles.Greeting>
          <Stack spacing={1.25} mb="10px">
            <InputLabel htmlFor="greeting-message">
              <FormattedMessage id="invoices.invoice-editor-greeting-message" />
            </InputLabel>
            <TextField
              sx={{ backgroundColor: theme.palette.background.paper }}
              multiline
              minRows={2}
              fullWidth
              id="article-name"
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'settings.autosize-height-based-on-content-lines' })}
              {...getFieldProps('settingsTemplate.welcomeText')}
            />
          </Stack>

          <Styles.BlockTooltip>
            <Tooltip arrow title={!values?.data?.id ? intl.formatMessage({ id: 'invoices.invoice-editor-tooltip-assigned-event' }) : ''}>
              <Stack spacing={1.25} direction="row" justifyContent="flex-end">
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  onClick={handleImportPrepayments}
                  disabled={isPrepaymentLoading || !values?.data}
                  startIcon={isPrepaymentLoading && <CircularProgress size={'20px'} />}
                >
                  <FormattedMessage id="invoices.invoice-editor-import-prepayments" />
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  onClick={handleImportArticles}
                  disabled={isArticlesLoading || !values?.data}
                  startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
                >
                  <FormattedMessage id="invoices.invoice-editor-import-articles" />
                </Button>
              </Stack>
            </Tooltip>
            {/* {tooltipTextForDisabledButtons()} */}
          </Styles.BlockTooltip>
        </Styles.Greeting>
        <DialogImportWarning
          isOpen={importedAgain?.isOpen}
          closeDialog={handleCloseWarningImport}
          handleImportWithReplacement={handleImportWithReplacement}
          handleImportAll={handleImportAll}
        />
      </BaseStyles.WrapperContent>
    </>
  );
};

export default GreetingTextImportButtons;
