import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { Box, Button, Card, Stack, Table, TableBody, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { AddCircle16Regular } from '@fluentui/react-icons';
import { useTheme } from '@mui/material/styles';
import { sortOrderType } from 'modules/builder/components/widgets/TableWidget/types';
import { useCurrentCurrencyFormat } from 'shared/hooks/regionalSettings';
import TableDynamicRow from 'modules/offers/components/TableDynamicRow';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { sortTable } from 'modules/invoices/utils/sortTableHelpers';
import { FieldNamesForSortingType } from 'modules/invoices/types';
import { EEditorArticleItemType, IEditorArticle } from 'modules/editor/types/editor';
import { EArticlesValueIOfferEdit, EOfferEditorArticleOptionalType, EOfferEditorArticleType, IOfferEditor } from 'shared/types/offer';
import { checkIsTableOptional, newDataOfArticles } from 'modules/offers/utils/editorTable';
import OptionalTextImportButtons from 'modules/offers/components/OptionalTextImportButtons';
import EditorResultCalculation from 'modules/editor/components/EditorResultCalculation';
import * as Styles from './OfferEditorTable.styles';

type TableTitles = 'name' | 'price' | 'tax' | 'unit' | 'count' | 'order' | 'total';

interface ITableTitles {
  name: TableTitles;
  intlId: string;
  isSortable?: boolean;
}
const tableTitles: ITableTitles[] = [
  { name: 'order', intlId: 'invoices.invoice-editor-column-order' },
  { name: 'name', intlId: 'invoices.invoice-editor-column-description', isSortable: true },
  { name: 'tax', intlId: 'invoices.invoice-editor-column-vat', isSortable: true },
  { name: 'price', intlId: 'invoices.invoice-editor-column-price', isSortable: true },
  { name: 'unit', intlId: 'invoices.invoice-editor-column-unit', isSortable: true },
  { name: 'count', intlId: 'invoices.invoice-editor-column-quantity', isSortable: true },
  { name: 'total', intlId: 'invoices.invoice-editor-column-amount', isSortable: true }
];

const FIELD_NAMES_MAP = {
  priceBrutto: 'price',
  priceNetto: 'price',
  totalNet: 'total',
  totalGross: 'total',
  name: 'name',
  tax: 'tax',
  count: 'count',
  unit: 'unit'
};

const OfferEditorTable = () => {
  const theme = useTheme();
  const [sortedColumnId, setSortedColumnId] = useState<null | FieldNamesForSortingType>(null);
  const [sortedColumnOptionalId, setSortedColumnOptionalId] = useState<null | FieldNamesForSortingType>(null);
  const [order, setOrder] = useState<sortOrderType>('asc');
  const [orderOptional, setOrderOptional] = useState<sortOrderType>('asc');

  const currencySign = useCurrentCurrencyFormat();

  const { values, setFieldValue, setValues } = useFormikContext<IOfferEditor>();

  const addRow = (isOptional?: boolean) => {
    const nameField = isOptional ? EArticlesValueIOfferEdit.ArticlesOptional : EArticlesValueIOfferEdit.Articles;
    const type = isOptional ? EOfferEditorArticleOptionalType.Optional : EOfferEditorArticleType.Custom;
    const newValueArticle = [
      ...values[nameField],
      {
        type: type,
        name: ``,
        tax: 0,
        quantity: 0,
        amount: 0,
        order: values.articles.length + 1,
        priceItems: [{ type: EEditorArticleItemType.Custom, count: 0, priceNetto: 0, priceBrutto: 0, unit: '' }],
        totalGross: 0,
        totalNet: 0,
        _innerId: uuidv4()
      } as IEditorArticle<EOfferEditorArticleType | EOfferEditorArticleOptionalType>
    ];
    setFieldValue(nameField, newValueArticle);
  };

  const handleSortClick = (name: ITableTitles['name'], tableName: string) => {
    const selectedOrder = checkIsTableOptional(tableName) ? orderOptional : order;
    const newDataForOrder = selectedOrder === 'asc' ? 'desc' : 'asc';
    let newName = name as string;
    if (name === 'price') {
      newName = values.taxType === QuestionnaireTaxType.Net ? 'priceNetto' : 'priceBrutto';
    }
    if (name === 'total') {
      newName = values.taxType === QuestionnaireTaxType.Net ? 'totalNet' : 'totalGross';
    }
    const newOrders = sortTable({
      articles: values[tableName as keyof IOfferEditor] as IEditorArticle[],
      sortedColumnId: newName as FieldNamesForSortingType,
      order: newDataForOrder
    });

    setFieldValue(tableName, newOrders);

    if (checkIsTableOptional(tableName)) {
      setOrderOptional(newDataForOrder);
      setSortedColumnOptionalId(newName as FieldNamesForSortingType);
    } else {
      setOrder(newDataForOrder);
      setSortedColumnId(newName as FieldNamesForSortingType);
    }
  };

  const checkIsColumnActive = (tableName: string, name: TableTitles): boolean => {
    if (checkIsTableOptional(tableName)) {
      return (
        sortedColumnOptionalId === name || (!!sortedColumnOptionalId && FIELD_NAMES_MAP[sortedColumnOptionalId] === name && !!orderOptional)
      );
    }
    return sortedColumnId === name || (!!sortedColumnId && FIELD_NAMES_MAP[sortedColumnId] === name && !!order);
  };

  const onDragEnd = (dropResult: DropResult) => {
    const getNewDataOfArticles = newDataOfArticles({ dropResult, values });
    !!getNewDataOfArticles && setValues(getNewDataOfArticles);
  };

  const tablesOfArticles = [
    { name: EArticlesValueIOfferEdit.Articles, list: values?.articles },
    { name: EArticlesValueIOfferEdit.ArticlesOptional, list: values?.articlesOptional }
  ];

  const selectedOrderTable = (tableName: string) => (checkIsTableOptional(tableName) ? orderOptional : order);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {tablesOfArticles.map((table, index) => (
        <Styles.Wrapper isOptionalTable={checkIsTableOptional(table.name)} key={`${table.name}-${index}`}>
          <Droppable droppableId={table.name}>
            {(provided) => (
              <Stack spacing={0}>
                {checkIsTableOptional(table.name) && <OptionalTextImportButtons />}
                <Card
                  sx={{
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px inset, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    backgroundColor: theme.palette.background.paper
                  }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px'
                    }}
                  >
                    <TableHead
                      sx={{
                        borderTop: 'none',
                        borderBottom: '3px solid rgba(0, 0, 0, 0.02)'
                      }}
                    >
                      <Styles.TableHeaderRow key="title">
                        {tableTitles.map(({ intlId, name, isSortable }) => (
                          <TableCell
                            size="small"
                            align="left"
                            key={name}
                            sortDirection={checkIsColumnActive(table.name, name) ? selectedOrderTable(table.name) : false}
                          >
                            <TableSortLabel
                              active={checkIsColumnActive(table.name, name)}
                              direction={checkIsColumnActive(table.name, name) ? selectedOrderTable(table.name) : 'asc'}
                              onClick={() => handleSortClick(name, table.name)}
                              sx={{ minWidth: 130, display: isSortable ? 'inherit' : 'none', paddingLeft: '10px' }}
                            >
                              <FormattedMessage id={intlId} />
                            </TableSortLabel>
                            {!isSortable && <FormattedMessage id={intlId} />}
                          </TableCell>
                        ))}
                      </Styles.TableHeaderRow>
                    </TableHead>
                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                      <FieldArray name={table.name} key={table.name}>
                        {(props) => <TableDynamicRow {...props} currencySign={currencySign} nameFields={table.name} />}
                      </FieldArray>
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </Card>
              </Stack>
            )}
          </Droppable>
          <Box display="flex" justifyContent="center" margin="1rem 0">
            <Button
              onClick={() => addRow(checkIsTableOptional(table.name))}
              variant="outlined"
              size="small"
              startIcon={<AddCircle16Regular />}
            >
              <FormattedMessage id="invoices.invoice-editor-table-add-line" />
            </Button>
          </Box>
          {!checkIsTableOptional(table.name) && (
            <Box paddingBottom="10px">
              <EditorResultCalculation />
            </Box>
          )}
        </Styles.Wrapper>
      ))}
    </DragDropContext>
  );
};

export default OfferEditorTable;
