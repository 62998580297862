import { Box, TextField as TextFieldMui, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface ITextFieldProps {
  isTitle?: boolean;
  maxWidth?: number;
  minWidth?: number;
  inputPadding?: string;
  fieldType?: string;
}

export const BoxWrapper = styled(Box)<ITextFieldProps & TextFieldProps>(({ isTitle = false, minWidth, maxWidth }) => ({
  '& .MuiOutlinedInput-root': { paddingRight: 0 },
  '& .MuiOutlinedInput-input': { padding: '9px 14px', minWidth, maxWidth },
  '& .MuiInputBase-multiline': { padding: '0' },
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  '& .MuiInputBase-input': {
    fontWeight: isTitle ? '500' : 'normal'
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#262626',
    fontWeight: 500,
    fontSize: '14px'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '12px'
  }
}));

export const TextField = styled(TextFieldMui)<ITextFieldProps & TextFieldProps>(({ fieldType, isTitle }) => ({
  whiteSpace: 'pre-line',
  minWidth: 'fit-content',
  '& .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      border: 'none !important',
      backgroundColor: 'transparent !important',
      '&:focus': {
        border: 'none !important'
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { border: 'none !important' },
    padding: '0px',
    border: 'none !important',
    backgroundColor: 'transparent !important',

    '& input': {
      padding: '0px'
    },
    '&:disabled': {
      '-webkitTextFillColor': '#262626'
    },
    '& .MuiInputAdornment-root': {
      width: 'auto',
      '& .MuiIconButton-root': {
        padding: '0px'
      }
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px',
    cursor: 'text',
    maxWidth: fieldType === 'date' ? '90px' : fieldType === 'time' ? '70px' : fieldType === 'dateTime' ? '150px' : 'auto',
    textAlign: fieldType === 'date' || fieldType === 'time' || fieldType === 'dateTime' ? 'center' : 'left',
    width: '100%',
    '&:disabled': {
      '-webkitTextFillColor': '#262626'
    },
    '&::after': {
      display: 'none'
    },
    minWidth: isTitle ? 'auto' : 'fit-content'
  },
  '& .Mui-focused': {
    boxShadow: 'none !important'
  }
}));
