import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  backgroundColor: theme.palette.primary.lighter,
  '@media print': { backgroundColor: 'white', border: `1px dashed #d9d9d9` },
  ':not(:last-of-type)': {
    marginBottom: '10px'
  }
}));

export const PreviewSectionWrapper = styled(Box)(({ theme }) => ({
  padding: '0px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '6px',
  '@media print': {
    padding: '0px',
    backgroundColor: 'white'
  }
}));

interface IPreviewSectionTitleProps {
  isExpanded: boolean;
}

export const PreviewSectionTitle = styled(Box)<IPreviewSectionTitleProps>(({ theme, isExpanded }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 10px 10px 20px',
  borderBottom: isExpanded ? `1px dashed ${theme.palette.secondary.light}` : 'none',
  backgroundColor: theme.palette.secondary.lighter,
  borderRadius: isExpanded ? '6px 6px 0 0' : '6px',
  '@media print': {
    padding: '10px 20px 5px 20px',
    borderBottom: '1px dashed #d9d9d9',
    color: 'black',
    backgroundColor: '#fafafb',
    '& button': {
      display: 'none'
    }
  }
}));

interface ISectionHeaderProps {
  isExpanded: boolean;
}

export const SectionHeader = styled(Box)<ISectionHeaderProps>(({ theme, isExpanded }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '16px',
  borderBottom: isExpanded ? `1px dashed ${theme.palette.secondary.light}` : 'none',
  backgroundColor: theme.palette.secondary.lighter,
  borderRadius: isExpanded ? '6px 6px 0 0' : '6px',
  '@media print': {
    borderBottom: '1px dashed #d9d9d9'
  },
  '& .MuiBox-root': {
    width: '80%',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
}));

interface ISectionContentProps {
  isTemplate: boolean;
}

export const SectionContent = styled(Box)<ISectionContentProps>(({ isTemplate }) => ({
  height: '100%',
  padding: isTemplate ? '10px' : '22px',
  '@media print': {
    padding: '10px 20px',
    color: 'black'
  }
}));

export const HeaderActionsContainer = styled('section')(() => ({
  display: 'flex',
  alignItems: 'right',
  minWidth: '200px'
}));
