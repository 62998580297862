import { roundNumbers } from 'modules/invoices/utils/calculateHelpers';
import { DEFAULT_LANGUAGE_SETTINGS, SETTINGS_PRICE_FORMAT } from 'shared/сonstants';

/**
 * Method to return number value to price in German/English format
 * @param value - value
 * @param languageCode - regional settings of tenant
 */
export const formatPriceByLanguage = (value: undefined | string | number, languageCode?: string) => {
  const defaultRegionSettings = SETTINGS_PRICE_FORMAT!.find((setting) => setting.code === DEFAULT_LANGUAGE_SETTINGS);
  const settings = SETTINGS_PRICE_FORMAT?.find((lang) => lang.code === languageCode) ?? defaultRegionSettings;
  if (value !== undefined) {
    const valueAsNumber = Number(value);
    const valueRounded = typeof valueAsNumber === 'number' ? roundNumbers(valueAsNumber) : value;
    const valueFormat = new Intl.NumberFormat(settings!.code, { style: 'currency', currency: settings!.currency }).format(
      Number(valueRounded)
    );
    return valueFormat.replace(settings!.symbol, '');
  }
  return value;
};
