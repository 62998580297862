import { isValid, parseISO } from 'date-fns';
export const formatDate = (date: Date | null, symbol: string) => {
  if (!date) return;
  return new Date(date).toLocaleDateString('en-GB').padStart(10, '0').replace(/\//g, symbol);
};

/**
 * Method to check if the string is Date in format '2023-07-01T14:07:39.72+00:00', '2023-08-01T12:52:59.517+00:00'
 * @param val - value
 */
export const checkStringIsDate = (val: string) => {
  const parsedDate = parseISO(val);
  return isValid(parsedDate);
};
