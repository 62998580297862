import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Card, Table, TableCell, TableHead, TableSortLabel, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { AddCircle16Regular } from '@fluentui/react-icons';
import { sortOrderType } from 'modules/builder/components/widgets/TableWidget/types';
import { useCurrentCurrencyFormat } from 'shared/hooks/regionalSettings';
import { EInvoiceEditorArticleType, IInvoiceEditor } from 'shared/types/invoice';
import TableDynamicRow from 'modules/invoices/components/TableDynamicRow';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { sortTable } from 'modules/invoices/utils/sortTableHelpers';
import { FieldNamesForSortingType } from 'modules/invoices/types';
import TableDndContainer from 'modules/invoices/components/TableDndContainer';
import { reorderArray } from 'modules/builder/utils/reorderArray';
import { EEditorArticleItemType, IEditorArticle } from 'modules/editor/types/editor';
import * as Styles from './InvoiceEditorTable.styles';

type TableTitles = 'name' | 'price' | 'tax' | 'unit' | 'count' | 'order' | 'total';

interface ITableTitles {
  name: TableTitles;
  intlId: string;
  isSortable?: boolean;
}
const tableTitles: ITableTitles[] = [
  { name: 'order', intlId: 'invoices.invoice-editor-column-order' },
  { name: 'name', intlId: 'invoices.invoice-editor-column-description', isSortable: true },
  { name: 'tax', intlId: 'invoices.invoice-editor-column-vat', isSortable: true },
  { name: 'price', intlId: 'invoices.invoice-editor-column-price', isSortable: true },
  { name: 'unit', intlId: 'invoices.invoice-editor-column-unit', isSortable: true },
  { name: 'count', intlId: 'invoices.invoice-editor-column-quantity', isSortable: true },
  { name: 'total', intlId: 'invoices.invoice-editor-column-amount', isSortable: true }
];

const FIELD_NAMES_MAP = {
  priceBrutto: 'price',
  priceNetto: 'price',
  totalNet: 'total',
  totalGross: 'total',
  name: 'name',
  tax: 'tax',
  count: 'count',
  unit: 'unit'
};

const InvoiceEditorTable = () => {
  const [sortedColumnId, setSortedColumnId] = useState<null | FieldNamesForSortingType>(null);
  const [order, setOrder] = useState<sortOrderType>('asc');
  const theme = useTheme();
  const currencySign = useCurrentCurrencyFormat();

  const { values, setFieldValue } = useFormikContext<IInvoiceEditor>();

  useEffect(() => {
    if (!sortedColumnId || !order) {
      return;
    }
    const newOrders = sortTable({ articles: values.articles, sortedColumnId, order });
    setFieldValue('articles', newOrders);
  }, [sortedColumnId, order, setFieldValue]);

  const addRow = () => {
    setFieldValue('articles', [
      ...(values?.articles ?? []),
      {
        type: EInvoiceEditorArticleType.Custom,
        name: '',
        tax: 0,
        quantity: 0,
        amount: 0,
        order: values.articles.length + 1,
        priceItems: [{ type: EEditorArticleItemType.Custom, count: 0, priceNetto: 0, priceBrutto: 0, unit: '' }],
        totalGross: 0,
        totalNet: 0,
        _innerId: uuidv4()
      } as IEditorArticle
    ]);
  };

  const handleSortClick = (name: ITableTitles['name']) => {
    let newName = name as string;
    if (name === 'price') {
      newName = values.taxType === QuestionnaireTaxType.Net ? 'priceNetto' : 'priceBrutto';
    }
    if (name === 'total') {
      newName = values.taxType === QuestionnaireTaxType.Net ? 'totalNet' : 'totalGross';
    }
    setOrder((prev) => {
      return prev === 'asc' ? 'desc' : 'asc';
    });
    setSortedColumnId(newName as FieldNamesForSortingType);
  };

  const checkIsColumnActive = (sortedColumnId: null | FieldNamesForSortingType, name: TableTitles, order: sortOrderType): boolean => {
    return sortedColumnId === name || (!!sortedColumnId && FIELD_NAMES_MAP[sortedColumnId] === name && !!order);
  };

  const updateOrder = useCallback(
    (indexFrom: number, indexTo: number) => {
      const result = reorderArray(indexFrom, indexTo, values.articles);
      const articlesWithNewOrder = result.map((article, index) => ({ ...article, order: index + 1 }));
      setFieldValue('articles', articlesWithNewOrder);
    },
    [values.articles, setFieldValue]
  );

  return (
    <Box sx={{ p: '0 33px' }}>
      <Card
        sx={{
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px inset, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Table
          aria-label="simple table"
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '10px'
          }}
        >
          <TableHead
            sx={{
              borderTop: 'none',
              borderBottom: '3px solid rgba(0, 0, 0, 0.02)'
            }}
          >
            <Styles.TableHeaderRow key="title">
              {tableTitles.map(({ intlId, name, isSortable }) => (
                <TableCell
                  size="small"
                  align="left"
                  key={name}
                  sortDirection={checkIsColumnActive(sortedColumnId, name, order) ? order : false}
                >
                  <TableSortLabel
                    active={checkIsColumnActive(sortedColumnId, name, order)}
                    direction={checkIsColumnActive(sortedColumnId, name, order) ? order : 'asc'}
                    onClick={() => handleSortClick(name)}
                    sx={{ minWidth: 130, display: isSortable ? 'inherit' : 'none', paddingLeft: '10px' }}
                  >
                    <FormattedMessage id={intlId} />
                  </TableSortLabel>
                  {!isSortable && <FormattedMessage id={intlId} />}
                </TableCell>
              ))}
            </Styles.TableHeaderRow>
          </TableHead>
          <TableDndContainer updateOrder={updateOrder}>
            <FieldArray name="articles">{(props) => <TableDynamicRow {...props} currencySign={currencySign} />}</FieldArray>
          </TableDndContainer>
        </Table>
      </Card>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Button onClick={addRow} variant="outlined" size="small" startIcon={<AddCircle16Regular />}>
          <FormattedMessage id="invoices.invoice-editor-table-add-line" />
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceEditorTable;
