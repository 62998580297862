import * as React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useIntl } from 'react-intl';
import { TextFieldProps } from '@mui/material';
import * as Styles from './NumberInput.styles';

interface INumberInput {
  icon?: string | React.ReactNode | React.ReactElement;
  value: NumberFormatProps['value'];
  defaultValue?: NumberFormatProps['defaultValue'];
  handleChange?: ({ name, value }: { name: string; value: number | string }) => void;
}

const NumberInput = ({
  icon,
  value,
  onChange,
  handleChange,
  defaultValue,
  ref: _ref,
  type: _type,
  decimalScale = 2,
  ...rest
}: INumberInput & NumberFormatProps & TextFieldProps) => {
  const { locale } = useIntl();
  const thousandSeparator = locale === 'de' ? '.' : ',';
  const decimalSeparator = locale === 'de' ? ',' : '.';

  const handleChangeWithTypeConversion: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (!handleChange) return;
    let newValue: string | number = e.currentTarget.value;
    if (newValue === '') {
      handleChange({ name: e.currentTarget.name, value: newValue });
      return;
    }
    newValue = newValue.replaceAll(thousandSeparator, '');
    if (decimalSeparator === ',') {
      newValue = newValue.replace(decimalSeparator, '.');
    }
    newValue = Number.isNaN(Number(newValue)) ? '' : Number(newValue);
    handleChange({ name: e.currentTarget.name, value: newValue });
  };

  return (
    <NumberFormat
      {...rest}
      onChange={handleChange ? handleChangeWithTypeConversion : onChange}
      type={'text'}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      fixedDecimalScale
      defaultValue={defaultValue}
      value={value}
      isNumericString={false}
      customInput={Styles.TextField as React.ComponentType<TextFieldProps>}
      InputProps={{
        endAdornment: icon && (
          <Styles.InputAdornment position="end">
            <Styles.IconContainer>{icon}</Styles.IconContainer>
          </Styles.InputAdornment>
        )
      }}
    />
  );
};

export default NumberInput;
