import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { useSelector } from 'store';
import { selectQuestionnaireSettings } from 'store/builder/builder.selectors';
import { ICalculateParams } from 'shared/types/invoice';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import { calculateFinalAmount, calculateNetTotalForGross, calculateTotal, listTaxValues } from 'modules/editor/utils/editResultCalculation';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import NumberInput from 'modules/invoices/components/NumberInput';
import { BLOCKED_SYMBOL_MINUS } from 'shared/сonstants';
import { skipEnterInTextField } from 'shared/utils/form';
import { ValuesEditorType } from 'modules/editor/types/editor';
import * as Styles from './EditorResultCalculation.styles';
import { additionalStylesIconInput, finalAmount } from './EditorResultCalculation.styles';

const EditorResultCalculation = () => {
  const intl = useIntl();
  const baseSettings = useSelector(selectQuestionnaireSettings);
  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();

  const totalAmount = useMemo(() => {
    return calculateTotal(values.articles, values?.taxType);
  }, [values.articles, values.taxType]);

  const taxValues = useMemo(() => {
    return listTaxValues(values.articles, values?.taxType, intl);
  }, [values.articles, values?.taxType, intl]);

  const additionalTotalNet =
    values.taxType === QuestionnaireTaxType.Gross
      ? [
          {
            title: intl.formatMessage({ id: 'invoices.invoice-editor-result-total-net' }),
            value: calculateNetTotalForGross(totalAmount, taxValues, values.discount ?? 0),
            name: 'totalAmountNet'
          }
        ]
      : [];

  const params: ICalculateParams[] = [
    {
      title: intl.formatMessage({ id: 'invoices.invoice-editor-result-total' }),
      value: totalAmount,
      name: 'totalAmount'
    },
    ...taxValues,
    {
      title: intl.formatMessage({ id: 'invoices.invoice-editor-result-discount' }),
      value: values?.discount,
      name: 'discount',
      isInput: true,
      blockedSymbols: BLOCKED_SYMBOL_MINUS
    },
    ...additionalTotalNet
  ];

  return (
    <BaseStyles.WrapperContent>
      <Styles.Container>
        {params.map((param) => (
          <Styles.Section key={param.name}>
            <Styles.Title>{param.title}</Styles.Title>
            <Styles.Value>
              {!param.isInput && <FormatPrice value={param.value ?? 0} baseSettings={baseSettings} isHideSymbol />}
              {param.isInput && (
                <NumberInput
                  onKeyDown={skipEnterInTextField}
                  fullWidth
                  name={param.name}
                  value={param.value ?? 0}
                  handleChange={({ name, value }) => setFieldValue(name, value)}
                  icon="%"
                  sx={additionalStylesIconInput}
                  allowNegative={false}
                />
              )}
            </Styles.Value>
          </Styles.Section>
        ))}
      </Styles.Container>
      <Divider />
      <Styles.Container>
        <Styles.Section>
          <Styles.Title>{intl.formatMessage({ id: 'invoices.invoice-editor-final-amount' })}</Styles.Title>
          <Styles.Value>
            <FormatPrice
              value={calculateFinalAmount(values, taxValues) ?? 0}
              baseSettings={baseSettings}
              isHideSymbol
              styles={finalAmount}
            />
          </Styles.Value>
        </Styles.Section>
      </Styles.Container>
    </BaseStyles.WrapperContent>
  );
};

export default EditorResultCalculation;
