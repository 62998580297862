import { WidgetsType } from 'modules/builder/types';
import { SingleChoiceWidgetType } from 'modules/builder/types/choiceWidgetsType';
import { ILinkSource, ILinkTarget } from 'shared/types/questioner-template-link';

export enum LinkingAction {
  show = 'show',
  hide = 'hide'
}

export interface ILinkingWidgetNav {
  id: string;
  label: string;
  options: SingleChoiceWidgetType['optionLabels'];
  type: WidgetsType['type'];
}
export interface ILinkingSubPageNav {
  label: string;
  id: string;
  widgets?: ILinkingWidgetNav[];
}

export interface ILinkingPageNav {
  label: string;
  id: string;
  subPages?: ILinkingSubPageNav[] | null;
  widgets?: ILinkingWidgetNav[] | null;
}

interface ILinkingSourceItem {
  optionId?: string | null;
  pageId?: number | null;
  pageSystemId?: string | null;
  subPageSystemId?: string | null;
  subPageId?: number | null;
  widgetId?: string | null;
  label?: string | null;
}

export interface ILinkObjectForSelect {
  linkId: string;
  action: LinkingAction;
  source: Partial<ILinkSource>;
  target: Partial<ILinkTarget>;
}

export interface ILinkObject {
  action: LinkingAction;
  source: ILinkingSourceItem;
}
interface IUpdateLinkPayload<T> {
  linkId: ILinkObjectForSelect['linkId'];
  link: Partial<T>;
}

export type UpdateSelectedFnType = (option: ILinkObjectForSelect['source'], linkId: string) => void;
export type UpdateLinkHandlerType<T> = (linkData: IUpdateLinkPayload<T>) => void;
export type RemoveLinkHandlerType = (linkId: ILinkObjectForSelect['linkId']) => void;
